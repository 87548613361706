var maree = {

    intervalList: [],

    clear: function () {
        $.each(maree.intervalList, function (k, interval) {
            clearInterval(interval);
        })
        maree.intervalList = [];
    },

    init: function () {

        maree.clear();

        $('[data-maree]').each(function () {

            var _self = this;

            var now = new Date();

            var month = now.getMonth() + 1;
            var day = now.getDate();
            // if(month <= 9){ month = '0'+month; }
            // if(day <= 9){ day = '0'+day; }
            var year = now.getFullYear();
            var hour = now.getHours();
            var min = now.getMinutes();
            var date = year + '-' + month + '-' + day + ' ' + hour + ':' + min;

            maree.get(['now', date], function (data) {
                if (data) {
                    maree.displayData(_self, data);

                    maree.intervalList.push(setInterval(function () {
                        maree.displayData(_self, data);
                    }, 60000));
                }
            });
        });
    },

    get: function (data, callback) {
        $.get(template_path + '/components/Marees/ajax/maree.php', {type: data[0], date: data[1]}, function (result) {
            if (result.success == 1) {
                callback(result.data);
            }
        }, 'json');
    },

    getFromTo: function (data, callback) {
        $.get(document.location.href, {type: data[0], from: data[1], to: data[2]}, function (result) {
            callback(result);
        });
    },


    displayData: function (el, data) {

        data['dateTime'] = data['dateTime'].replace(/-/g, '/');

        var nextTime = new Date(data['dateTime']);

        var currData = data;

        var time = (nextTime.getTime()) - (new Date().getTime());

        var hour = Math.floor(time / 3600000);
        time -= hour * 3600000;
        var min = Math.ceil(time / 60000);
        if (min <= 9) {
            min = '0' + min;
        }
        // console.log(min);
        if (min == 60) {
            hour++;
            min = 0;
        }
        // if(hour <= 9){
        //     hour = '0'+hour;
        // }

    }
};

maree.init();


var calendarDateFormatMaree = 'DD/MM/YYYY';
var siteLanguageMaree = $('html').attr('lang');
var calendarDateLanguageMaree = siteLanguageMaree.substr(0, siteLanguageMaree.indexOf('-'));
var startDate = moment(new Date()).format(calendarDateFormatMaree);


var from_maree_date = $('.js-wrapper-dates-maree');
if (from_maree_date) {

    $('input.date', from_maree_date).each(function () {
        var container = '.js-wrapper-dates-maree';
        var inline = false;

        if (!isTouchDevice()) {
            $(this).attr('type', 'text');
            $(this).dateRangePicker({
                inline: inline,
                container: container,
                singleDate: true,
                alwaysOpen: false,
                singleMonth: true,
                showShortcuts: false,
                showTopbar: false,
                monthSelect: true,
                hoveringTooltip: false,
                selectForward: false,
                yearSelect: [2022, 2030],
                startOfWeek: 'monday',
                startDate: startDate,
                customArrowPrevSymbol: '<span class="icon-ico-arrow-left"></span>',
                customArrowNextSymbol: '<span class="icon-ico-arrow-right"></span>',
                format: calendarDateFormatMaree,
                language: calendarDateLanguageMaree,
            }).bind('datepicker-change', function (event, obj) {
                if ($('[data-jsdatestatus="from"]').val() && $('[data-jsdatestatus="to"]').val()) {
                    maree.getFromTo(['maree', $('[data-jsdatestatus="from"]').val(), $('[data-jsdatestatus="to"]').val()], function (html) {
                        if (html) {
                            const maree_html = $('.js-component-maree-content', html).html();

                            if (maree_html !== '') {
                                $('.js-component-maree-content').html(maree_html);
                            }
                        }
                    });
                }
            });
        } else {
            var data_date = $(this).val();
            data_date = data_date.split('/').reverse().join('-');
            $(this).val(data_date);
            $(this).attr('type', 'date');

            $(this).on('change', function (eventObject) {
                // pour gérer le placeholder
                if ($(this).val()) {
                    $(this).addClass('is-filled');
                } else {
                    $(this).removeClass('is-filled');
                }

                var date_status = $(this).data('jsdatestatus');
                //$('[data-jsdatestatus="' + date_status + '"]', '#barre-filtres [data-jsgroupdate="' + groupes_input_dates + '"]').val($(this).val());
                $('[data-jsdatestatus="' + date_status + '"]').val($(this).val());

                maree.getFromTo(['maree', $('[data-jsdatestatus="from"]').val(), $('[data-jsdatestatus="to"]').val()], function (html) {
                    if (html) {
                        const maree_html = $('.js-component-maree-content', html).html();

                        if (maree_html !== '') {
                            $('.js-component-maree-content').html(maree_html);
                        }
                    }
                });
            })
        }
    });
}

