class Favoris {
    isLoading = false;
    selectors = {
        cards: ".card",
        btnFavorites: ".cover-sit__actions .btn-favorite",
        btnCreateFavlist: ".js-btn-create-favlist",
        btnConfirmRemoveFavlist: ".js-btn-confirm-remove-favlist",
        itemsFavlist: ".js-item-favlist",
        popinShadow: ".js-popin-shadow",
        panelFavlists: "[data-panel='panel-favlists']",
        favorisListContainer: ".js-favoris-list-container",
        formCreateFav: ".js-form-create-fav-list",
        formEditFav: ".js-form-edit-fav-list",
        formDeleteFav: ".js-form-delete-fav-list",
        formAddToFavList: ".js-form-add-fav-to-favlist",
    };

    constructor(container) {
        this.container = container;

        this.getEls();
        this.overlayEvents();
        this.otherOverlayEvents();
        this.attachEventsToCards();
        this.attachEventsToHeaders();
        this.outsideEvents();

        // CRUD
        this.initFormCreate();
        this.initFormEdit();
        this.initFormDelete();
        this.initFormAddToFavList();
    }

    getEls() {
        this.itemsFavlist = [...this.container.querySelectorAll(this.selectors.itemsFavlist)];
        this.popinShadow = this.container.querySelector(this.selectors.popinShadow);
        this.btnConfirmRemoveFavlist = this.container.querySelector(this.selectors.btnConfirmRemoveFavlist);
        this.btnCreateFavlist = this.container.querySelector(this.selectors.btnCreateFavlist);
        this.panelFavlists = this.container.querySelector(this.selectors.panelFavlists);
        this.formCreateFav = this.container.querySelector(this.selectors.formCreateFav);
        this.formEditFav = this.container.querySelector(this.selectors.formEditFav);
        this.formDeleteFav = this.container.querySelector(this.selectors.formDeleteFav);
        this.formAddToFavToList = this.container.querySelector(this.selectors.formAddToFavList);
    }

    initFormCreate() {
        if (this.formCreateFav) {
            const inputName = this.formCreateFav.querySelector("input[type='text']");

            this.formCreateFav.addEventListener("submit", e => {
                e.preventDefault();

                if (!this.getLoadingState()) {
                    this.request("handle_add_favori_list", {
                        name: inputName.value
                    })
                        .then(response => {
                            if (response && response.success) {
                                // TODO : Gestion des erreurs
                                inputName.value = "";

                                this.refreshFavorites(true);
                                this.closePopin('popin-create-favlist');
                            }
                        });
                }
            })
        }
    }

    initFormEdit() {
        if (this.formEditFav) {
            const listId = this.formEditFav.querySelector("input[name='list_id']");
            const name = this.formEditFav.querySelector("input[name='favlist-name']");

            this.formEditFav.addEventListener("submit", e => {
                e.preventDefault();

                if (!this.getLoadingState()) {
                    this.request("handle_edit_favori_list", {
                        id: listId.value,
                        new_name: name.value
                    })
                        .then(response => {
                            if (response && response.success) {
                                this.refreshFavorites(true);
                                this.closePopin('popin-edit-favlist');
                            }
                        });
                }
            })
        }
    }

    initFormDelete() {
        if (this.formDeleteFav) {
            const list_id = this.formDeleteFav.querySelector("input[name='list_id']");

            this.formDeleteFav.addEventListener("submit", e => {
                e.preventDefault();

                if (!this.getLoadingState()) {
                    this.request("handle_remove_favori_list", {
                        id: list_id.value
                    })
                        .then(response => {
                            if (response && response.success) {
                                this.refreshFavorites(true);
                                this.closePopin('popin-remove-favlist');
                            }
                        })
                }
            })
        }
    }

    initFormAddToFavList() {
        if (this.formAddToFavToList) {
            this.formAddToFavToList.addEventListener("submit", e => {
                e.preventDefault();

                const ficheId = this.formAddToFavToList.querySelector("input[name='fiche_id']");
                const lists = [...this.formAddToFavToList.querySelectorAll("input[name='fav_lists[]']:checked")];

                if (ficheId && lists) {
                    const data = {
                        id: ficheId.value,
                        lists: []
                    };

                    lists.forEach(list => {
                        data.lists.push(list.value);
                    });

                    this.request("handle_add_to_favori_list", data)
                        .then(response => {
                            if (response && response.success) {
                                ficheId.value = null;
                                lists.forEach(list => list.checked = false);

                                this.refreshFavorites();
                                this.closePopin("popin-add-fav-to-favlist");
                            }
                        })
                }
            });
        }
    }

    overlayEvents() {
        this.container.querySelectorAll('[data-panel-open]').forEach((btn) => {
            if (!btn.classList.contains("parsed")) {
                btn.addEventListener('click', () => {
                    this.openPanel(btn.dataset.panelOpen);
                });

                btn.classList.add("parsed");
            }
        });

        this.container.querySelectorAll('[data-panel-close]').forEach((btn) => {
            if (!btn.classList.contains("parsed")) {
                btn.addEventListener('click', () => {
                    this.closePanel(btn.dataset.panelClose);
                });

                btn.classList.add("parsed");
            }
        });

        // Actions sur les boutons des listes
        this.itemsFavlist.forEach((item) => {
            if (!item.classList.contains("parsed")) {
                this.setItemFavlist(item);
            }
        });

        if (!this.btnCreateFavlist.classList.contains("parsed")) {
            this.btnCreateFavlist.addEventListener('click', () => {
                this.openPopin('popin-create-favlist');
            });

            this.btnCreateFavlist.classList.add("parsed");
        }
    }

    otherOverlayEvents() {
        this.container.querySelectorAll('[data-popin-close]').forEach((btn) => {
            btn.addEventListener('click', () => {
                this.closePopin(btn.dataset.popinClose);
            });
        });

        // Confirmation de suppression d'une liste dans la pop-in
        this.btnConfirmRemoveFavlist.addEventListener('click', () => {
            console.log('confirmRemoveFavlist');
        });
    }

    outsideEvents() {
        document.addEventListener("thListingAfterUpdate", () => {
            this.attachEventsToCards();
        })
    }

    setItemFavlist(item) {
        const btnEditFavlist = item.querySelector('.js-btn-edit-favlist');
        const btnRemoveFavlist = item.querySelector('.js-btn-remove-favlist');

        if (btnEditFavlist) {
            btnEditFavlist.addEventListener('click', () => {
                const id = btnEditFavlist.getAttribute("data-id");

                if (id) {
                    this.setFormListId(this.formEditFav, id);
                    const input = this.formEditFav.querySelector("input[name='favlist-name']");
                    const listName = this.container.querySelector(`[data-panel="${id}"] .title-overlay`);

                    if (input && listName) {
                        input.value = listName.textContent;
                    }

                    this.openPopin('popin-edit-favlist');
                }
            });
        }

        if (btnRemoveFavlist) {
            btnRemoveFavlist.addEventListener('click', () => {
                const id = btnEditFavlist.getAttribute("data-id");

                if (id) {
                    this.setFormListId(this.formDeleteFav, id);
                    this.openPopin('popin-remove-favlist');
                }
            });
        }
    }

    setFormListId(el, listId) {
        if (el) {
            const input = el.querySelector("input[name='list_id']");

            if (input) {
                input.value = listId;
            }
        }
    }

    openPanel(panelId) {
        const panel = this.container.querySelector(`[data-panel=${panelId}]`);

        if (panel) {
            if (!this.container.classList.contains("is-panel-open")) {
                this.container.classList.add("is-panel-open");
            }

            panel.classList.add('is-open');
            this.panelFavlists.classList.remove('is-open');
            this.container.querySelector('.btn-close').classList.remove('--bg-white');
        }
    }

    closePanel(panelId) {
        const panel = this.container.querySelector(`[data-panel= ${panelId} ]`);

        if (panel) {
            if (this.container.classList.contains("is-panel-open")) {
                this.container.classList.remove("is-panel-open");
            }

            panel.classList.remove('is-open');
            this.panelFavlists.classList.add('is-open');
            this.container.querySelector('.btn-close').classList.add('--bg-white');
        }
    }

    openPopin(popinId) {
        const popin = this.container.querySelector(`[data-popin=${popinId}]`);

        if (popin) {
            popin.classList.add('open');
            this.popinShadow.classList.add('open');
        }
    }

    // Fermeture d'une pop-in par-dessus l'overlay principal
    closePopin(popinId = null) {
        if (popinId) {
            const popin = this.container.querySelector(`[data-popin= ${popinId} ]`);

            if (popin) {
                popin.classList.remove('open');
                this.popinShadow.classList.remove('open');
            }
            // Fermeture au clic sur l'overlay shadow
        } else {
            this.popinShadow.classList.remove('open');
            this.container.querySelectorAll('.popin').forEach((popin) => {
                if (popin.classList.contains('open')) {
                    popin.classList.remove('open');
                }
            });
        }
    }

    attachEventsToHeaders() {
        const header = document.querySelector(this.selectors.btnFavorites);

        if (header) {
            header.addEventListener("click", e => {
                e.preventDefault();

                this.addBtnFavoriteEvent(header);
            });
        }
    }

    attachEventsToCards() {
        const cards = [...document.querySelectorAll(this.selectors.cards)];

        if (cards) {
            cards.forEach(card => {
                if (!card.classList.contains("favoris-parsed")) {
                    card.classList.add("favoris-parsed");

                    card.addEventListener("click", e => {
                        if (e.target.classList.contains("btn-favorite")) {
                            e.preventDefault();

                            this.addBtnFavoriteEvent(e.target);
                        } else if (e.target.classList.contains("js-btn-remove-favori-favlist")) {
                            const listId = e.target.getAttribute("data-liste-id");
                            const ficheId = card.getAttribute("data-post-id");
                            e.preventDefault();

                            if (listId && listId) {
                                this.removeFavoritesFromList(ficheId, listId);
                            }
                        }
                    });
                }
            })
        }
    }

    addBtnFavoriteEvent(el) {
        const id = el.getAttribute("data-addpanier");

        if (id) {
            if (el.classList.contains("is-favorite")) {
                this.removeFromFavorites(id)
                    .then(response => {
                        if (response && response.success) {
                            el.classList.remove("is-favorite");

                            this.refreshFavorites();
                        }
                    });
            } else {
                if (this.formAddToFavToList) {
                    const inputHiddenFicheID = this.formAddToFavToList.querySelector("input[name='fiche_id']");

                    if (inputHiddenFicheID) {
                        inputHiddenFicheID.value = id;
                    }
                }
                th_overlay.open("overlay-favoris");

                const listFavoris = [...this.container.querySelectorAll(".js-item-favlist")];

                if (listFavoris.length > 1) {
                    this.openPopin('popin-add-fav-to-favlist');
                }

                this.addToFavorites(id)
                    .then(response => {
                        if (response && response.success) {
                            el.classList.add("is-favorite");

                            this.refreshFavorites();
                        }
                    });
            }
        }
    }

    removeFavoritesFromList(ficheId, listId) {
        if (!this.getLoadingState()) {
            this.enableLoadingState();

            this.request("handle_remove_favori", {
                id: ficheId,
                list_id: listId
            })
                .then(response => {
                    if (response && response.success && response.data.html) {
                        this.replaceFavoritesHTML(response.data.html);

                        if (response.data.list_id) {
                            this.openPanel(response.data.list_id);
                        }

                        this.disableLoadingState();
                    }
                })
        }
    }

    async addToFavorites(id) {
        return this.request("handle_add_favori", {
            "id": id
        });
    }

    async removeFromFavorites(id) {
        return this.request("handle_remove_favori", {
            "id": id
        });
    }


    refreshFavorites(refreshSelect = false) {
        if (!this.getLoadingState()) {
            this.enableLoadingState();

            this.request("handle_get_favoris")
                .then(response => {
                    this.disableLoadingState();

                    if (response && response.success && response.data) {
                        this.replaceFavoritesHTML(response.data.favoris_list);

                        if (refreshSelect) {
                            const favorisListSelect = this.container.querySelector(".js-favoris-list-select");

                            if (favorisListSelect && response.data.favoris_list_select) {
                                favorisListSelect.innerHTML = response.data.favoris_list_select;

                                $('.frm_checkbox > label').append('<span></span>');
                            }
                        }
                    }
                })
        }
    }

    replaceFavoritesHTML(html) {
        const favorisListContainer = this.container.querySelector(this.selectors.favorisListContainer);

        if (favorisListContainer && html) {
            favorisListContainer.innerHTML = html;

            this.getEls();
            this.overlayEvents();
            this.attachEventsToCards();
        }
    }

    getLoadingState() {
        return this.container.classList.contains("is-loading") && this.isLoading;
    }

    enableLoadingState() {
        this.isLoading = true;

        if (!this.container.classList.contains("is-loading")) {
            this.container.classList.add("is-loading");
        }
    }

    disableLoadingState() {
        this.isLoading = false;

        if (this.container.classList.contains("is-loading")) {
            this.container.classList.remove("is-loading");
        }
    }

    async request(action, data) {
        const formData = new FormData();
        formData.append("action", action);

        if (data) {
            Object.keys(data).forEach((dataKey) => {
                if (Array.isArray(data[dataKey])) {
                    formData.append(dataKey, JSON.stringify(data[dataKey]));
                } else {
                    formData.append(dataKey, data[dataKey]);
                }
            });
        }

        return fetch("/wp-admin/admin-ajax.php", {
            method: "POST",
            body: formData
        })
            .then(response => response.json())
    }
}

(function () {
    // Init
    const overlay = document.querySelector('.js-overlay-favoris');

    if (overlay) {
        const favoriClass = new Favoris(overlay);
    }
})();