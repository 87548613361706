class Profile {
    request(form, action, callback) {
        // États du formulaire
        this.resetForm(form);
        this.hideFormMessage(form);

        // Check d'abord si le recaptcha est rempli, si il ne l'est pas on ne soumets rien
        const recaptcha = form.querySelector("#g-recaptcha-response");

        if (recaptcha && !recaptcha.value.length) {
            console.log("recaptcha not yet there");

            return;
        }

        form.classList.add('is-sending');

        const inputsError = [...form.querySelectorAll('.is-error')];

        if (inputsError.length) {
            inputsError.forEach(input => input.classList.remove('is-error'));
        }

        // Création de la donnée à envoyer au Back
        const data = new FormData(form);
        data.append("action", action);

        fetch('/wp-admin/admin-ajax.php', {
            method: 'POST',
            body: data
        })
            .then(response => response.json())
            .then(response => {
                form.classList.remove('is-sending');

                if (response && response.data) {
                    if (response.success) {
                        this.setFormMessage(form, false, response.data.message);

                        if (response.data.redirect) {
                            window.location.reload();
                        }

                        if (callback) {
                            callback(response);
                        }
                    } else {
                        this.setFormMessage(form, true, response?.data?.message);

                        if (response.data.errors.length) {
                            response.data.errors.forEach(error => {
                                const input = form.querySelector(`input[name=${error}]`);

                                if (input) {
                                    input.classList.add('is-error');
                                }
                            })

                            if (response.data.errors.includes("g-recaptcha-response")) {
                                document.dispatchEvent(new Event("thRefreshRecaptcha"));
                            }
                        }
                    }
                } else {
                    this.setFormMessage(form, true);
                }
            });
    }

    resetForm(form) {
        form.classList.remove('is-success');
        form.classList.remove('is-error');
    }

    hideFormMessage(form) {
        const responseContainer = [...form.querySelectorAll('.response-container')];

        if (responseContainer) {
            responseContainer.forEach(response => response.classList.remove('is-active'));
        }
    }

    setFormMessage(form, error, message) {
        const responseContainer = [...form.querySelectorAll('.response-container')];

        if (responseContainer) {
            responseContainer.forEach(response => {
                if (error) {
                    if (!form.classList.contains('is-error')) {
                        form.classList.add('is-error');
                    }
                } else {
                    if (!form.classList.contains('is-success')) {
                        form.classList.add('is-success');
                    }
                }

                if (response && message) {
                    response.classList.add('is-active');

                    response.textContent = message;
                }
            })
        }
    }
}