if (eval("typeof Profile") === "function") {
    class HideFiches extends Profile {
        constructor(el) {
            super();

            this.el = el;

            this.getEls();
            this.setPostIdOnOverlayOpen();
            this.events();

            const previousFunction = listingSitDefaultWithMap.options.afterUpdate;

            listingSitDefaultWithMap.options.afterUpdate = () => {
                if (previousFunction) {
                    previousFunction();
                }

                th_overlay.parse();
                this.setPostIdOnOverlayOpen();
            }
        }

        getEls() {
            this.form = this.el.querySelector('form');
        }

        setPostIdOnOverlayOpen() {
            const cardsSit = [...document.querySelectorAll("a.card.card-sit")];
            const inputPostID = this.form.querySelector("input[name='post_id']");

            if (cardsSit.length) {
                cardsSit.forEach(card => {
                    const postId = card.getAttribute("data-id");

                    if (postId) {
                        card.addEventListener('click', e => {
                            if (e.target.getAttribute("data-overlay-open") === "overlay-hide-fiche") {
                                inputPostID.value = postId;
                            }
                        });
                    }
                })
            }
        }

        events() {
            this.form.addEventListener("submit", e => {
                e.preventDefault();

                this.request(this.form, 'handle_hide_fiche_sit', response => {
                    if (response.success) {
                        const inputs = this.form.querySelectorAll("input[name='hide_duree']");

                        inputs.forEach(input => {
                            input.checked = false;
                        });

                        th_overlay.close("overlay-hide-fiche");

                        document.dispatchEvent(new Event('th_submit_form'));
                    }
                });
            });

            th_overlay.addCallbackClose(overlayId => {
                if (overlayId === "overlay-hide-fiche") {
                    this.resetForm(this.form);
                    this.hideFormMessage(this.form);
                }
            })
        }
    }

    document.addEventListener('DOMContentLoaded', () => {
        const hideFiche = document.querySelector('#overlay-hide-fiche');

        if (hideFiche) {
            new HideFiches(hideFiche);
        }
    })
}