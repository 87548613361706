var th_overlay = {
    selector_overlay: '.overlay',
    selector_overlay_shadow: '.shadow-overlay',
    currentOpenedOverlay: null,

    callbackOpen: [],
    callbackClose: [],

    preventDefault: true,

    init: function (settings) {
        th_overlay.parseOverlayShadow($('body'));
        th_overlay.parse($('body'));
        th_overlay.openOnLoad();

        if (settings) {
            $.each(settings, function (key, setting) {
                if (th_overlay[key]) {
                    th_overlay[key] = setting;
                }
            });
        }
    },

    addCallbackOpen: function (callback) {
        th_overlay.callbackOpen.push(callback);
    },
    addCallbackClose: function (callback) {
        th_overlay.callbackClose.push(callback);
    },

    parseOverlayShadow: function () {
        $(th_overlay.selector_overlay_shadow).on('click', function (e) {
            e.preventDefault();

            if (th_overlay.currentOpenedOverlay) {
                const correspondingOverlay = document.querySelector(`#${th_overlay.currentOpenedOverlay}`);

                if (correspondingOverlay && correspondingOverlay.classList.contains("no-close-on-shadow")) {
                    return;
                }
            }

            th_overlay.close();
        });
    },

    parse: function (el) {
        $(th_overlay.selector_overlay).each(function () {
            var overlayId = this.id;
            var targetOpen = $('a[href="#' + overlayId + '"]:not(.parsed-overlay):not(.close), [data-overlay-open="' + overlayId + '"]:not(.parsed-overlay)');

            targetOpen.addClass('parsed-overlay')
                .on('click', function (e) {
                    e.preventDefault();
                    var idOverlay = $(this).attr('href');
                    if (!idOverlay) {
                        idOverlay = $(this).attr('data-overlay-open');
                    } else {
                        idOverlay = idOverlay.replace('#', '');
                    }

                    th_overlay.open(idOverlay, true, true);
                });

            $('a[href="#' + overlayId + '"].close:not(.parsed-overlay), [data-overlay-close="' + overlayId + '"]:not(.parsed-overlay)')
                .addClass('parsed-overlay')
                .on('click', function (e) {
                    e.preventDefault();
                    var idOverlay = $(this).attr('href');
                    if (!idOverlay) {
                        idOverlay = $(this).attr('data-overlay-close');
                    } else {
                        idOverlay = idOverlay.replace('#', '');
                    }

                    if (!idOverlay) {
                        idOverlay = null;
                    }

                    if (th_overlay.preventDefault) {
                        e.preventDefault();
                    }
                    th_overlay.close(idOverlay);
                });
        });
    },

    openOnLoad: function () {
        var current_hash = (window.location.hash).split('#');

        if (current_hash) {
            $.each(current_hash, function (index, overlay_id) {
                if (overlay_id && overlay_id.length) {
                    try {
                        var corresponding_overlay = $("#" + overlay_id);
                        var notOpenOnLoad = corresponding_overlay.attr('not-open-onload');

                        if ($(corresponding_overlay).hasClass("overlay") && notOpenOnLoad !== "true") {
                            return th_overlay.open(overlay_id);
                        }
                    } catch (error) {
                        return false;
                    }
                }
            });
        }

        $(th_overlay.selector_overlay).each(function () {
            if (this.classList.contains("open-by-default")) {
                const overlayId = this.id;

                return th_overlay.open(overlayId);
            }
        });
    },

    open: function (overlayId, openShadow, doCallback) {
        if (openShadow !== false) {
            openShadow = true;
        }
        if (doCallback !== false) {
            doCallback = true;
        }

        $(th_overlay.selector_overlay + ".open").each(function (e) {
            th_overlay.close($(this).attr('id'), false, true)
        });

        $('#' + overlayId).addClass('open');

        // ajout de l'option data-overlay-disable-hash (sans valeur) pour désactiver l'ajout du hash dans l'url
        const overlay = document.getElementById(overlayId);
        const disableHashAttr = overlay.dataset.overlayDisableHash;
        if (disableHashAttr !== '' && disableHashAttr !== 'true') {
            window.location.hash = overlayId;
        }

        if (openShadow == true) {
            $(th_overlay.selector_overlay_shadow).addClass('open');
        }

        if (doCallback == true) {
            $.each(th_overlay.callbackOpen, function (k, callback) {
                callback(overlayId);
            });
        }

        th_overlay.currentOpenedOverlay = overlayId;
    },

    close: function (overlayId, closeShadow, doCallback) {
        if (closeShadow !== false) {
            closeShadow = true;
        }
        if (doCallback !== false) {
            doCallback = true;
        }

        if (!overlayId) {
            overlayId = th_overlay.currentOpenedOverlay;
        }

        const overlay = document.getElementById(overlayId);
        const disableHashAttr = overlay.dataset.overlayDisableHash;
        if (disableHashAttr !== '' && disableHashAttr !== 'true') {
            window.location.hash = " ";
            history.replaceState(null, null, ' ');
        }

        if (overlayId) {
            $('#' + overlayId).removeClass('open');
            if ($(th_overlay.selector_overlay + '.open').length == 0 && closeShadow) {
                $(th_overlay.selector_overlay_shadow).removeClass('open');
            }
        } else {
            $(th_overlay.selector_overlay + '.open').removeClass('open');
            if (closeShadow) {
                $(th_overlay.selector_overlay_shadow).removeClass('open');
            }
        }

        if (doCallback) {
            $.each(th_overlay.callbackClose, function (k, callback) {
                callback(overlayId);
            });
        }

        th_overlay.currentOpenedOverlay = null;
    }
};
