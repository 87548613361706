document.addEventListener("DOMContentLoaded", () => {
    const allInputsAnneeNaissance = [...document.querySelectorAll("input[name='annee_de_naissance']")];

    if (allInputsAnneeNaissance) {
        allInputsAnneeNaissance.forEach(input => {
            input.addEventListener("keydown", e => {
                if (e.target.value.length > 3 || isNaN(parseInt(e.key, 10))) {
                    e.preventDefault();
                }
            });
        });
    }
});
