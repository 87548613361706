if (eval("typeof Profile") === "function") {
    class Connexion extends Profile {
        constructor(el) {
            super();

            this.el = el;

            this.getEls();
            this.events();
        }

        getEls() {
            this.form = this.el.querySelector('form');
        }

        /**
         * Attache les events
         */
        events() {
            this.form.addEventListener('submit', e => {
                e.preventDefault();

                this.request(this.form, 'handle_connexion');
            })
        }
    }

    document.addEventListener('DOMContentLoaded', () => {
        const connexionContainer = document.querySelector('#overlay-connexion');

        if (connexionContainer) {
            new Connexion(connexionContainer);
        }
    })
}