const pageFirstConnexion = document.querySelector(".page-first-connexion");
if (pageFirstConnexion) {
    // Intro
    const form = pageFirstConnexion.querySelector(".js-page-first-connexion-form")
    const intro = pageFirstConnexion.querySelector(".js-page-first-connexion-intro");
    const btnsChoice = [...pageFirstConnexion.querySelectorAll(".js-page-first-connexion-choice")];
    const formActions = [...pageFirstConnexion.querySelectorAll(".js-page-first-connexion-form-actions button")];
    const agenda = pageFirstConnexion.querySelector(".js-page-first-connexion-agenda");

    if (btnsChoice) {
        btnsChoice.forEach(btn => {
            btn.addEventListener("click", () => {
                const correspondingForm = pageFirstConnexion.querySelector(`.js-page-first-connexion-filters[data-type='${btn.getAttribute("data-type")}']`);

                if (correspondingForm) {
                    intro.classList.remove("is-active");

                    correspondingForm.classList.add("is-active");
                }

                if (btn.getAttribute("data-type") === "sortir" && agenda) {
                    agenda.style.display = "none";
                }

                if (formActions) {
                    formActions.forEach(btn => !btn.classList.contains("is-active") && btn.classList.add("is-active"));
                }
            });
        });
    }

    // Skip
    pageFirstConnexion.querySelector(".js-page-first-connexion-skip").addEventListener("click", () => {
        form.querySelector("input[type='hidden'][name='skip']").value = 1;

        form.submit();
    });

    // Apply
    pageFirstConnexion.querySelector(".js-page-first-connexion-apply-existing").addEventListener("click", () => {
        form.querySelector("input[type='hidden'][name='apply']").value = 1;

        form.submit();
    });

    // Form
    const filtreRayon = pageFirstConnexion.querySelector(".distance-profil");

    if (filtreRayon) {
        new DistanceCalculator(filtreRayon);
    }

    pageFirstConnexion.querySelector(".js-page-first-connexion-submit").addEventListener("click", () => {
        form.submit();
    });

    const datepicker = pageFirstConnexion.querySelector(".js-page-first-connexion-datepicker");
    const inputFrom = pageFirstConnexion.querySelector("input[type='hidden'][name='date_profil_du']");
    const inputTo = pageFirstConnexion.querySelector("input[type='hidden'][name='date_profil_au']");
    const btnDisplayCalendar = pageFirstConnexion.querySelector('.js-page-first-connexion-open-datepicker');

    $(datepicker).attr('type', 'text');
    $(datepicker).attr('placeholder', '');
    var container = '.filters-agenda__inner';

    $(datepicker).dateRangePicker({
        inline: false,
        container: container,
        singleDate: false,
        alwaysOpen: false,
        singleMonth: true,
        showShortcuts: false,
        showTopbar: false,
        monthSelect: true,
        yearSelect: [2020, 2030],
        startOfWeek: 'monday',
        customArrowPrevSymbol: '<span class="icon-arrow-left"></span>',
        customArrowNextSymbol: '<span class="icon-arrow-right"></span>',
        format: "DD/MM/YYYY",
        language: $('html').attr('lang').substr(0, $('html').attr('lang').indexOf('-')),
        setValue: function (s, s1, s2, inst) {
            inputFrom.val = s1;
            inputTo.val = s2;
        },
    });

    [...pageFirstConnexion.querySelectorAll(".js-page-first-connexion-pickdate")].forEach(picker => {
        picker.addEventListener("click", e => {
            const dateFrom = picker.getAttribute("data-start");
            const dateTo = picker.getAttribute("data-end");

            inputFrom.value = dateFrom;
            inputTo.value = dateTo;

            $(datepicker).data('dateRangePicker').setDateRange(dateFrom.split('-').reverse().join('/'), dateTo.split('-').reverse().join('/'));
        });
    });

    btnDisplayCalendar.addEventListener("click", e => {
        e.preventDefault();

        setTimeout(() => {
            datepicker.click();
        }, 100);
    });
}