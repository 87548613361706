var SearchWP = {
    _selector: {
        container: '.overlay-search',
        form: '.js-search-form',
        resContainer: '.js-search-results',
        btn: '.js-search-form-submit',
    },

    current_page: 1,
    max_page: false,
    is_loading: false,


    init: function () {
        if (wp_debug) {
            console.log('Recherche initialisée');
        }

        SearchWP.listen();
    },

    listen: function () {

        $(SearchWP._selector.btn, SearchWP._selector.container).on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $(SearchWP._selector.form, SearchWP._selector.container).submit();
        });

        $(SearchWP._selector.form, SearchWP._selector.container).on('submit', function (e) {

            // $(SearchWP._selector.resContainer, SearchWP._selector.container).html("<span class='loading'>" + $(SearchWP._selector.container).data("loadtext") + "</span>");
            $(SearchWP._selector.resContainer, SearchWP._selector.container).html('<div class="loading-text"><p>' + $(SearchWP._selector.container).data("loadtext") + '</p></div>');

            e.preventDefault();
            e.stopPropagation();

            var url = $(this).attr('action');
            var query = $(this).serialize();

            $.get(
                url,
                query,
                function (responseHTML) {
                    SearchWP.updateContent(responseHTML);
                }
            );
        });
    },

    loadmore: function () {
        url = $('#pagination a.--next', SearchWP._selector.container).attr('href');
        if (url && SearchWP.is_loading === false) {
            SearchWP.is_loading = true;
            $(SearchWP._selector.resContainer, SearchWP._selector.container).append('<div class="loading-text"><p>' + $(SearchWP._selector.container).data("loadtext") + '</p></div>');
            $.get(
                url,
                false,
                function (responseHTML) {
                    SearchWP.updateContent(responseHTML);

                    SearchWP.current_page++;
                    SearchWP.is_loading = false;
                }
            );
        }
    },

    updateContent: function (responseHTML) {
        var html = $(responseHTML).find('.js-search-result').html();
        var pagination = $(responseHTML).find('#pagination').html();
        var nbresults = $(responseHTML).find('#nbresults').html();
        var nbresults_founded = $(responseHTML).find('.js-nbresults_founded .nbresults_value').html();

        if(nbresults_founded > 0 ) {
            $('.js-nbresults_founded').removeClass('is-empty');
            $('.js-nbresults_founded .nbresults_value').html(nbresults_founded);
        } else {
            $('.js-nbresults_founded').removeClass('is-empty');
            $('.js-nbresults_founded .nbresults_value').html(nbresults_founded);
        }

        $(SearchWP._selector.resContainer + ' .loading-text', SearchWP._selector.container).remove();
        $(SearchWP._selector.resContainer, SearchWP._selector.container).append(html);

        $('#pagination', SearchWP._selector.container).html(pagination);
        $('#nb-search-result .nbresult').html(nbresults);

        $('#pagination .--next', SearchWP._selector.container).on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            SearchWP.loadmore();
        });

        SearchWP.max_page = parseInt($('.pagination-number a:last-child', SearchWP._selector.container).text());

        lazyLoadInstance = new LazyLoad({
            elements_selector: ".lazy"
        });
    }

};

SearchWP.init();
