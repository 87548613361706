(function () {
    const alerts = [...document.querySelectorAll(".js-alert-filters")];

    if (alerts.length) {
        alerts.forEach(alert => {
            const btnClose = alert.querySelector(".btn-close");

            if (btnClose) {
                btnClose.addEventListener("click", e => {
                    e.preventDefault();

                    alert.classList.add("is-closed");
                });
            }
        });
    }
})();