var carteInteractive = {
    selector: {
        form: ".js-carte-interactive-form",
        map: ".js-carte-interactive-map"
    },
    form: null,
    map: null,

    init: function () {
        const mapContainer = document.querySelector(carteInteractive.selector.map);
        const formContainer = document.querySelector(carteInteractive.selector.form);

        if (mapContainer && formContainer && carteInteractivePoints) {
            carteInteractive.map = th_maps.initSingleMap(mapContainer);
            carteInteractive.form = formContainer;
            carteInteractive.map.zoomControl.setPosition('bottomright');

            carteInteractive.loadPoints(carteInteractive.map, carteInteractivePoints);
            carteInteractive.inputEvents();
        }
    },

    loadPoints: function (map, json) {
        Object.keys(json).forEach(key => {
            let icon = null;

            if (json[key].icon) {
                icon = `icon-${key}`;

                th_maps.addMarkerIcon(icon, {
                    iconUrl: json[key].icon,
                    shadowUrl: null,
                    iconSize: [36, 36],
                    iconAnchor: [18, 36]
                });
            }

            json[key].points.forEach(point => {
                const lat = parseFloat(point.lat);
                const lng = parseFloat(point.lng);

                const marker = th_maps.createMarker(null, {
                    lat,
                    lng
                }, icon ? icon : "default", `selection-${key}`);


                marker.on("click", function () {
                    if (carteInteractive.lastActiveMarker) {
                        if (icon) {
                            carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons[icon]);
                        } else {
                            carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons["default"]);
                        }
                        carteInteractive.lastActiveMarker = null;
                    }
                    marker._icon.classList.add("is-active");
                    carteInteractive.lastActiveMarker = marker;

                    const data = new FormData();
                    data.append("action", "get_carte_interactive_point");
                    data.append("post_id", point.id);

                    fetch("/wp-admin/admin-ajax.php", {
                        method: "POST",
                        body: data
                    })
                        .then(response => response.json())
                        .then(response => {
                            if (response.html) {
                                const infoWindow = th_maps.createInfoWindow(response.html);

                                infoWindow.on("remove", function () {
                                    if (icon) {
                                        carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons[icon]);
                                    } else {
                                        carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons["default"]);
                                    }
                                });
                                infoWindow.setLatLng({lat: lat, lng: lng}).openOn(map);

                                const lazyLoadInstance = new LazyLoad({
                                    elements_selector: ".lazy"
                                });
                            }
                        });
                });
            });

            if (json[key].open) {
                th_maps.showGroupMarker(`selection-${key}`, carteInteractive.map);
            }
        });

        carteInteractive.centerOnSelectedMarkers();
    },

    inputEvents: function () {
        const inputs = [...carteInteractive.form.querySelectorAll("input[type='checkbox']")];

        inputs.forEach(input => {
            input.addEventListener("change", () => {
                if (input.checked) {
                    th_maps.showGroupMarker(`selection-${input.value}`, carteInteractive.map);
                } else {
                    th_maps.hideGroupMarker(`selection-${input.value}`);
                }

                carteInteractive.centerOnSelectedMarkers();
            })
        })
    },

    centerOnSelectedMarkers: function () {
        const inputs = [...carteInteractive.form.querySelectorAll("input[type='checkbox']")];
        const markers = [];

        inputs.forEach(input => {
            if (input.checked && th_maps.markersGrouped[`selection-${input.value}`]) {
                th_maps.markersGrouped[`selection-${input.value}`].forEach(marker => markers.push(marker));
            }
        })

        if (markers.length) {
            const group = new L.featureGroup(markers);
            carteInteractive.map.fitBounds(group.getBounds(), {padding: [50, 50]});
        }
    }
};

document.addEventListener("DOMContentLoaded", () => {
    carteInteractive.init();
});
