class InputDatesSynchronises {
    calendar;
    from;
    to;

    constructor(calendar, inputFrom, inputTo, speFilters, form) {
        this.inputFrom = inputFrom;
        this.inputTo = inputTo;
        this.speFilters = speFilters;

        this.createCalendar(calendar);
        this.initSpefilters();
    }

    createCalendar(calendar) {
        const calendarDateFormat = 'DD/MM/YYYY';

        this.calendar = $(calendar).dateRangePicker({
            inline: true,
            container: `#${calendar.getAttribute("id")}`,
            alwaysOpen: true,
            singleMonth: true,
            showShortcuts: false,
            showTopbar: false,
            startOfWeek: 'monday',
            startDate: moment().format(calendarDateFormat),
            hoveringTooltip: false,
            customArrowPrevSymbol: '<span class="icon-ico-arrow-left"></span>',
            customArrowNextSymbol: '<span class="icon-ico-arrow-right"></span>',
            format: calendarDateFormat,
            language: "fr",
            getValue: () => {
                let valueFrom = this.inputFrom.value;
                let valueTo = this.inputTo.value;

                if (valueFrom && valueTo) {
                    return valueFrom.split('-').reverse().join('/') + ' to ' + valueTo.split('-').reverse().join('/');
                }

                return '';
            },
            setValue: (s, s1, s2) => {
                this.inputFrom.value = s1.split('/').reverse().join('-');
                this.inputTo.value = s2.split('/').reverse().join('-');

                this.inputFrom.dispatchEvent(new Event("force-change"));

                this.speFilters.forEach(spefilter => {
                    const start = spefilter.getAttribute("data-start");
                    const end = spefilter.getAttribute("data-end");
                    
                    if (this.inputFrom.value !== start && this.inputTo.value !== end) {
                        spefilter.checked = false;
                    }
                });
            }
        });
    }

    initSpefilters() {
        this.speFilters.forEach(spefilter => {
            spefilter.addEventListener("change", () => {
                const start = spefilter.getAttribute("data-start");
                const end = spefilter.getAttribute("data-end");

                this.setData(start, end);
            });
        })
    }

    setData(from, to) {
        if (from && from.match(/\//)) {
            from = from.split('/').reverse().join('-');
        }

        if (to && to.match(/\//)) {
            to = to.split('/').reverse().join('-');
        }

        if (from !== this.from || to !== this.to) {
            this.from = from;
            this.to = to;

            this.onChange();

            return true;
        }

        return false;
    }

    onChange() {
        if (this.from && this.to) {
            console.log(this.from, this.to);
            const fromSlash = this.from.split('-').reverse().join('/');
            const toSlash = this.to.split('-').reverse().join('/');

            if (fromSlash && toSlash) {
                this.inputFrom.value = fromSlash;
                this.inputTo.value = toSlash;

                if (this.calendar) {
                    this.calendar.data('dateRangePicker').setDateRange(fromSlash, toSlash);
                }
            }
        }
    }
}