const overlayFeedback = document.querySelector("#overlay-feedback");

if (overlayFeedback) {
    // DISPLAY
    if (!localStorage.getItem("hasSeenFeedback") && !localStorage.getItem("hasSubmittedRating")) {
        const limitPageviews = parseInt(overlayFeedback.getAttribute("data-display-after"));
        let totalPageviews = parseInt(localStorage.getItem("feedbackPageviews"));
        if (!totalPageviews) {
            totalPageviews = 0;
        }

        if (totalPageviews >= limitPageviews) {
            localStorage.setItem("hasSeenFeedback", "1");
            th_overlay.open("overlay-feedback");
        } else {
            totalPageviews++;
            localStorage.setItem("feedbackPageviews", totalPageviews);
        }
    }

    // FORM
    let hasSubmitted = false;
    const formFeedback = overlayFeedback.querySelector("form");

    function handleResponse(form, message = "", type = "success") {
        const responseContainer = form.querySelector(".response-container");
        const allClasses = ["is-success", "is-error", "is-active"];

        if (responseContainer) {
            if (message) {
                responseContainer.textContent = message;
            }

            allClasses.forEach(className => responseContainer.classList.remove(className));

            switch (type) {
                case "success":
                    responseContainer.classList.add("is-success");
                    responseContainer.classList.add("is-active");
                    break;
                case "error":
                    responseContainer.classList.add("is-error");
                    responseContainer.classList.add("is-active");
                    break;
                default:
                    break;
            }
        }
    }

    formFeedback.addEventListener("submit", e => {
        e.preventDefault();

        if (!hasSubmitted && !localStorage.getItem("hasSubmittedRating")) {
            hasSubmitted = true;
            const formData = new FormData(formFeedback);

            if (formData.has("rating")) {
                formData.append("action", "handle_feedback");

                fetch("/wp-admin/admin-ajax.php", {
                    body: formData,
                    method: "POST"
                })
                    .then(response => response.json())
                    .then(response => {
                        if (response.success) {
                            localStorage.setItem("hasSubmittedRating", "1");
                            handleResponse(formFeedback, response.data.message, "success");
                        } else {
                            handleResponse(formFeedback, response.data.message ? response.data.message : "Erreur, veuillez ré-essayer.", "error");
                        }

                        hasSubmitted = false;
                    });
            } else {
                handleResponse(formFeedback);
                hasSubmitted = false;
            }
        } else {
            handleResponse(formFeedback, "Vous avez déjà soumis votre feedback.", "error");
        }
    });
}