if (eval("typeof Profile") === "function") {
    class Account extends Profile {
        constructor(el) {
            super();

            this.el = el;

            this.getEls();
            this.events();
        }

        getEls() {
            this.form = this.el.querySelector('form');

            // nav des panneaux dans l'overlay
            this.panelHome = this.el.querySelector('[data-panel="panel-home"]');

            // Ouverture / fermeture des panneaux (avec attributs data-panel-open / data-panel-close)
            this.el.querySelectorAll('[data-panel-open]').forEach((btn) => {
                btn.addEventListener('click', () => {
                    this.openPanel(btn.dataset.panelOpen);
                });
            });

            this.el.querySelectorAll('[data-panel-close]').forEach((btn) => {
                btn.addEventListener('click', () => {
                    this.closePanel(btn.dataset.panelClose);
                });
            });

            // Spé toolbar
            const btnGuide = document.querySelector(".toolbar .btn-toolbar[data-panel-open]");

            if (btnGuide) {
                btnGuide.addEventListener("click", () => {
                    const panel = btnGuide.getAttribute("data-panel-open");

                    if (panel) {
                        this.openPanel(panel);
                    }
                })
            }
        }

        /**
         * Ouverture / fermeture des panneaux
         */
        openPanel(panelId) {
            const panel = this.el.querySelector(`[data-panel= ${panelId} ]`);

            if (panel) {
                this.closePanel();

                panel.classList.add('is-open');
                this.panelHome.classList.remove('is-open');
                this.el.querySelector('.btn-close').classList.remove('--bg-white');
            }
        }

        closePanel(panelId) {
            if (!panelId) {
                const panels = [...this.el.querySelectorAll(`[data-panel]`)];

                if (panels) {
                    panels.forEach(panel => {
                        if (panel.classList.contains("is-open")) {
                            panel.classList.remove('is-open');
                        }
                    })
                }
            } else {
                const panel = this.el.querySelector(`[data-panel= ${panelId} ]`);
                if (panel) {
                    panel.classList.remove('is-open');
                    this.panelHome.classList.add('is-open');
                    this.el.querySelector('.btn-close').classList.add('--bg-white');
                }
            }
        }


        /**
         * Attache les events
         */
        events() {
            this.form.addEventListener('submit', e => {
                e.preventDefault();

                this.request(this.form, 'handle_modify_account', response => {
                    if (response && response.data && response.data.reload) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                });
            });

            this.distance = new DistanceCalculator(this.form);
        }
    }

    document.addEventListener('DOMContentLoaded', () => {
        const accountManager = document.querySelector('#overlay-profil');

        if (accountManager) {
            new Account(accountManager);
        }
    })
}