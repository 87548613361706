const installApp = [...document.querySelectorAll(".js-btn-install-app")];

var isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Samsung: function () {
        return navigator.userAgent.match(
            /SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i,
        );
    },
    Windows: function () {
        return (
            navigator.userAgent.match(/IEMobile/i) ||
            navigator.userAgent.match(/WPDesktop/i)
        );
    },
    any: function () {
        return (
            isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        );
    },
};

// use this to check if the user is already using your PWA - no need to prompt if in standalone
function isStandalone() {
    const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
    if (document.referrer.startsWith("android-app://")) {
        return true; // Trusted web app
    } else if ("standalone" in navigator || isStandalone) {
        return true;
    }
    return false;
}

if (installApp) {
    let deferredPrompt;
    window.addEventListener("beforeinstallprompt", (e) => {
        deferredPrompt = e;
    });

    installApp.forEach(btn => {
        btn.addEventListener('click', async () => {
            if (isMobile.iOS()) {
                th_overlay.open('overlay-alert-ios');
            } else {
                if (deferredPrompt) {
                    deferredPrompt.prompt();
                    const {outcome} = await deferredPrompt.userChoice;
                    if (outcome === 'accepted') {
                        deferredPrompt = null;
                    }
                }
            }
        });
    })
}
