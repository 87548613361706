if (eval("typeof Profile") === "function") {
    class Inscription extends Profile {
        constructor(el) {
            super();

            this.el = el;

            this.getEls();
            this.events();
        }

        getEls() {
            this.form = this.el.querySelector('form');
        }

        /**
         * Attache les events
         */
        events() {
            $(this.form).on('submit', e => {
                e.preventDefault();

                this.request(this.form, 'handle_inscription');
            });
        }
    }

    document.addEventListener('DOMContentLoaded', () => {
        const inscriptionContainer = document.querySelector('#overlay-inscription');

        if (inscriptionContainer) {
            new Inscription(inscriptionContainer);
        }
    })
}