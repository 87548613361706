const strates = [...document.querySelectorAll(".strate-newsletter")];

if (strates.length) {
    strates.forEach(strate => {
        const select = strate.querySelector("select");
        const link = strate.querySelector("a");

        if (select && link) {
            select.addEventListener("change", () => {
                const displayValue = window.getComputedStyle(link).getPropertyValue("display");
                if (displayValue === 'none') {
                    link.style.display = "inline-flex";
                }
                link.setAttribute("href", select.value)
            });
        }
    });
}