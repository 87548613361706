class DistanceCalculator {
    container;
    lat;
    lng;
    distance;
    map;
    circle;
    slider;
    mapContainer;
    tooltip;
    inputLat;
    inputLng;
    btnReset;
    listCommunes;
    geoloc;
    defaultOptions = {
        selectors: {
            slider: ".distance",
            mapContainer: ".distance-map",
            tooltip: ".tooltip",
            inputLat: "input[name='lat']",
            inputLng: "input[name='lng']",
            btnReset: ".btn-reset-distance",
            listCommunes: ".select-communes",
            geoloc: ".geoloc"
        },
        onMove: null
    }

    constructor(container, options) {
        this.container = container;
        this.options = {...this.defaultOptions, ...options};

        this.getEls();
        this.initDistanceMap();
        this.initDistanceRangeModifier();
        this.initBtnReset();
        this.initListCommunes();
        this.initGeoloc();
    }

    getEls() {
        if (this.options.selectors && this.container) {
            Object.keys(this.options.selectors).forEach((selector) => {
                this[selector] = this.container.querySelector(this.options.selectors[selector]);
            })
        }
    }

    initDistanceMap() {
        if (this.mapContainer) {
            this.lat = parseFloat(this.mapContainer.getAttribute('data-lat'));
            this.lng = parseFloat(this.mapContainer.getAttribute('data-lng'));
            this.distance = parseFloat(this.mapContainer.getAttribute('data-distance'));
            this.map = th_maps.initSingleMap(this.mapContainer);

            this.drawCircle(this.lat, this.lng, this.distance);

            this.map.addEventListener('drag', this.setCircleOnChange.bind(this));
            if (this.options.onMove) {
                this.map.addEventListener('dragend', this.options.onMove.bind(this));
            }
        }
    }

    initDistanceRangeModifier() {
        if (this.slider) {
            this.slider.addEventListener('input', () => {
                this.setDistance(this.slider.value);
                if (this.options.onMove) {
                    this.options.onMove();
                }

                this.setCircleOnChange(true);
            });
        }
    }

    setDistanceTooltip() {
        if (this.tooltip) {
            const val = this.slider.value;
            const min = this.slider.min ? this.slider.min : 0;
            const max = this.slider.max ? this.slider.max : 100;
            const newVal = Number(((val - min) * 100) / (max - min));
            this.tooltip.innerHTML = val + ' km';

            // Sorta magic numbers based on size of the native UI thumb
            this.tooltip.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
        }
    }

    initBtnReset() {
        if (this.btnReset) {
            this.btnReset.addEventListener('click', (e) => {
                e.preventDefault();

                this.setDistance(0);
                this.setLat(null);
                this.setLng(null);

                if (this.circle) {
                    this.map.removeLayer(this.circle);
                }
            })
        }
    }

    initListCommunes() {
        if (this.listCommunes) {
            new TomSelect(this.listCommunes, {
                maxOptions: 300,
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                render: {
                    option_create: function (data, escape) {
                        return `<div class="create">${tomSelectTranslation.add} <strong>` + escape(data.input) + '</strong>&hellip;</div>';
                    },
                    no_results: function (data, escape) {
                        return `<div class="no-results">${tomSelectTranslation.no_results}</div>`;
                    },
                }
            });

            this.listCommunes.addEventListener("change", () => {
                let value = this.listCommunes.value;

                if (value) {
                    value = value.split("|");

                    if (value.length && value[0] && value[1]) {
                        this.setLat(value[0]);
                        this.setLng(value[1]);

                        this.drawCircle(this.lat, this.lng, this.distance);

                        if (this.options.onMove) {
                            this.options.onMove();
                        }
                    }
                }
            });
        }
    }

    initGeoloc() {
        if (this.geoloc) {
            this.geoloc.addEventListener("click", (e) => {
                e.preventDefault();

                navigator.geolocation.getCurrentPosition(this.geolocSuccess.bind(this), this.geolocError.bind(this), {
                    enableHighAccuracy: true,
                    timeout: 10000
                })
            });
        }
    }

    geolocSuccess(position) {
        this.setLat(position.coords.latitude);
        this.setLng(position.coords.longitude);

        this.drawCircle(this.lat, this.lng, this.distance);
    }

    geolocError(error) {
        console.warn(error.message);
    }

    setCircleOnChange(force) {
        const center = this.map.getCenter();

        if ((this.lat !== center.lat && this.lng !== center.lng) || force) {
            this.setLat(center.lat);
            this.setLng(center.lng);
            this.drawCircle(this.lat, this.lng, this.distance);
        }
    }

    drawCircle(lat, lng, radius) {
        if (radius) {
            if (this.circle) {
                this.map.removeLayer(this.circle);
            }

            this.circle = L.circle([lat, lng], {
                radius: (radius * 1000) / 2
            });

            this.map.addLayer(this.circle);
            this.map.fitBounds(this.circle.getBounds(), {padding: [40, 40]});
        }
    }

    setDistance(distance) {
        this.distance = distance;

        if (this.slider) {
            this.slider.value = distance;
            this.slider.dispatchEvent(new Event("change"));
        }

        this.setDistanceTooltip();
    }

    setLat(lat) {
        this.lat = lat;

        if (this.inputLat) {
            this.inputLat.value = lat;
        }
    }

    setLng(lng) {
        this.lng = lng;

        if (this.inputLng) {
            this.inputLng.value = lng;
        }
    }
}