document.addEventListener("DOMContentLoaded", () => {
    const pageAccueil = document.querySelector("main.page-home");

    if (pageAccueil) {
        const form = pageAccueil.querySelector(".js-form-page-accueil");
        const links = [...pageAccueil.querySelectorAll('.strate-guides a[data-link]')];

        form.addEventListener("submit", e => {
            e.preventDefault();

            // 1- Création de l'URL
            const values = new FormData(form);
            const params = new URLSearchParams();

            for (let [key, val] of values.entries()) {
                if (val && key !== "m" && key !== "y") {
                    if (key !== 'from' || key !== 'to' || key !== 'date_du' || key !== 'date_au') {
                        params.append(key, val.split('/').reverse().join('-'));
                    } else {
                        params.append(key, val);
                    }
                }
            }

            // 2- Append de cette URL à tous les liens dans le bloc guide
            if (links) {
                links.forEach(link => link.setAttribute("href", `${link.getAttribute("data-link")}?${params}`))
            }
        });

        const filtreRayon = pageAccueil.querySelector(".distance-profil");

        if (filtreRayon) {
            new DistanceCalculator(filtreRayon, {
                onMove: () => {
                    form.requestSubmit();
                }
            });
        }

        const datesStrateLocation = $('.js-date-location');

        if (datesStrateLocation) {
            const inputFrom = datesStrateLocation[0].querySelector('input[data-jsdatestatus="from"]');
            const inputTo = datesStrateLocation[0].querySelector('input[data-jsdatestatus="to"]');
            const inputFromFMA = datesStrateLocation[0].querySelector('input[name="date_du"]');
            const inputToFMA = datesStrateLocation[0].querySelector('input[name="date_au"]');

            $('input.date', datesStrateLocation).each(function () {
                if (!isTouchDevice()) {
                    var container = '.js-date-location';
                    var inline = false;

                    $(this).attr('type', 'text');
                    $(this).dateRangePicker({
                        inline: inline,
                        container: container,
                        alwaysOpen: false,
                        singleMonth: true,
                        showShortcuts: false,
                        showTopbar: false,
                        monthSelect: true,
                        hoveringTooltip: false,
                        selectForward: false,
                        yearSelect: [2022, 2030],
                        startOfWeek: 'monday',
                        startDate: startDate,
                        customArrowPrevSymbol: '<span class="icon-ico-arrow-left"></span>',
                        customArrowNextSymbol: '<span class="icon-ico-arrow-right"></span>',
                        format: calendarDateFormatMaree,
                        language: calendarDateLanguageMaree,
                        setValue: (s, s1, s2) => {
                            inputFrom.value = s1;
                            inputTo.value = s2;
                            inputFromFMA.value = s1;
                            inputToFMA.value = s2;
                        }
                    }).bind('datepicker-change', function (s) {
                        if (form) {
                            form.requestSubmit();
                        }
                    });
                } else {
                    // empêche de sélectionner une date antérieure à la date courante
                    const today = new Date().toISOString().split('T')[0];
                    this.setAttribute('min', today);

                    this.addEventListener("change", e => {
                        // champ date rempli
                        if (this.value) {
                            // pour gérer le placeholder
                            this.classList.add('is-filled');

                            // on a remplit la date de départ -> on met comme minimum la date de départ pour la date de fin
                            if (this === inputFrom) {
                                inputTo.setAttribute('min', this.value);
                                // on a remplit la date de fin -> on met comme maximum la date de fin pour la date de départ
                            } else {
                                inputFrom.setAttribute('max', this.value);
                            }

                            // champ date vide ou effacé
                        } else {
                            this.classList.remove('is-filled');

                            // on supprime la date de départ -> minimum à la date d'aujourd'hui
                            if (this === inputFrom) {
                                inputTo.setAttribute('min', today);
                                // on supprime la date de fin -> suppression du maximum sur la date de départ
                            } else {
                                inputFrom.removeAttribute('max');
                            }
                        }

                        form.requestSubmit();
                    });
                }
            });
        }

        form.requestSubmit();
    }
});
