// OVERLAY MAP
function callbackMapOverlayMap(macarte) {
    macarte.zoomControl.setPosition('topleft');
}

// PAGE LISTING SIT
function callbackMapPageListingLEI(map) {
    map.zoomControl.remove();

    const openListingMapBtn = document.querySelector('.js-open-listing-map');
    const closeListingMapBtn = document.querySelector('.js-close-listing-map');
    const listingMap = document.querySelector('.js-listing-map');
    const listingPageContainer = document.querySelector('.js-listing-default-map');
    const backBtn = document.querySelector('.js-back-btn');
    const filtersBtn = document.querySelector('.js-open-filters-btn');

    const handleMapToggleDisplay = () => {
        listingMap.classList.toggle('open')
        listingPageContainer.classList.toggle('open')
        backBtn.classList.toggle('hidden')
        openListingMapBtn.classList.toggle('hidden')
        closeListingMapBtn.classList.toggle('hidden')
        filtersBtn.classList.toggle('--light')
        filtersBtn.classList.toggle('btn-icon')
        filtersBtn.classList.toggle('btn')
        listingSitDefaultWithMap.maps.map.update()
    }

    openListingMapBtn.addEventListener('click', handleMapToggleDisplay)
    closeListingMapBtn.addEventListener('click', handleMapToggleDisplay)

}

function attachEventToSITCards() {
    const cardsSit = [...document.querySelectorAll("a.card.card-sit")];

    if (cardsSit) {
        cardsSit.forEach(card => {
            card.addEventListener('click', e => {
                if (e.target.getAttribute("data-overlay-open")) {
                    e.preventDefault();
                }
            })
        })
    }
}

attachEventToSITCards();

// listing SIT classique - uniquement map en aside
var listingSitDefaultWithMap = new thListingManager({
    selectors: {
        listing: ".js-listing-default-map",
        map: '.js-listing-map',
    },
    mapOptions: {
        onlyDesktop: false,
    },
    mapOverlayOptions: {
        onlyMobile: true,
    },
    afterUpdate: function () {
        document.dispatchEvent(new CustomEvent("thListingAfterUpdate"));
    }
});

/* Initialisation du slider de la carte */
(function () {
    setTimeout(function () {

        // Init des sliders
        const sliders = document.querySelectorAll('.js-slider-overlay-map');
        sliders.forEach(function (slider) {
            initSlider(slider);
        });

        function initSlider(slider) {
            const splide = new Splide(slider, {
                breakpoints: {
                    600: {
                        arrows: false
                    },
                }
            }).mount();
        }

    }, 550);
})();
