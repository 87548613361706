document.addEventListener("DOMContentLoaded", () => {
    // input profil
    const calendarProfil = document.querySelector("#overlay-profil .js-profil-calendar-open");
    const inputFromProfil = document.querySelector("#overlay-profil input[name='date_profil_du']");
    const inputToProfil = document.querySelector("#overlay-profil input[name='date_profil_au']");
    const spefiltersProfil = [...document.querySelectorAll("#overlay-profil input[name='date_spe']")];

    if (calendarProfil && inputFromProfil && inputToProfil && spefiltersProfil) {
        new InputDatesSynchronises(calendarProfil, inputFromProfil, inputToProfil, spefiltersProfil);
    }

    // input guide
    const calendarGuide = document.querySelector("#overlay-guide .js-guide-calendar-open");
    const inputFromGuide = document.querySelector("#overlay-guide input[name='date_guide_du']");
    const inputToGuide = document.querySelector("#overlay-guide input[name='date_guide_au']");
    const spefiltersGuide = [...document.querySelectorAll("#overlay-guide input[name='date_spe']")];

    if (calendarGuide && inputFromGuide && inputToGuide && spefiltersGuide) {
        new InputDatesSynchronises(calendarGuide, inputFromGuide, inputToGuide, spefiltersGuide);
    }

    // input listing
    const calendarListing = document.querySelector("#overlay-facette-date .js-listing-calendar-open");
    const inputFromListing = document.querySelector("#overlay-filtres input[data-jsdatestatus='from']");
    const inputToListing = document.querySelector("#overlay-filtres input[data-jsdatestatus='to']");
    const spefiltersListing = [...document.querySelectorAll(".js-listing-barre-filtre input[name='date_spe']")];

    if (calendarListing && inputFromListing && inputToListing && spefiltersListing) {
        new InputDatesSynchronises(calendarListing, inputFromListing, inputToListing, spefiltersListing);
    }
});