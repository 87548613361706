class ThAccordion {
    constructor(domNode) {
        this.rootEl = domNode;
        this.btnTrigger = this.rootEl.querySelector('button[aria-expanded]');

        const controlsId = this.btnTrigger.getAttribute('aria-controls');
        this.contentEl = document.getElementById(controlsId);

        this.isOpen = this.btnTrigger.getAttribute('aria-expanded') === 'true';

        // add event listeners
        this.btnTrigger.addEventListener('click', this.onButtonClick.bind(this));

        // Accordéon ouvert à l'initialisation
        if (this.rootEl.classList.contains('is-open')) {
            this.toggle(!this.isOpen);
        }
    }

    onButtonClick() {
        this.toggle(!this.isOpen);
    }

    toggle(isOpen) {
        // don't do anything if the open state doesn't change
        if (isOpen === this.isOpen) {
            return;
        }

        // update the internal state
        this.isOpen = isOpen;

        // handle DOM updates
        this.btnTrigger.setAttribute('aria-expanded', `${isOpen}`);
        if (isOpen) {
            this.contentEl.removeAttribute('aria-hidden');
            this.contentEl.removeAttribute('inert');
            this.rootEl.classList.add('is-open');
        } else {
            this.contentEl.setAttribute('aria-hidden', 'true');
            this.contentEl.setAttribute('inert', '');
            this.rootEl.classList.remove('is-open');
        }
    }

    // Add public open and close methods for convenience
    open() {
        this.toggle(true);
    }

    close() {
        this.toggle(false);
    }
}