(function () {

    // Quand une checkbox / radio est coché, ajoute une classe sur le container parent
    $('.bloc-form .frm_checkbox input[type="checkbox"]').on('change', function () {
        if($(this).is(':checked')) {
            $(this).closest('.frm_checkbox').addClass('is-checked');
        } else {
            $(this).closest('.frm_checkbox').removeClass('is-checked');
        }
    });


    $('.bloc-form .frm_radio input[type="radio"]').on('change', function () {
        var $container = $(this).closest('.frm_opt_container');

        $($container).find('input[type="radio"]').each(function() {
            if(this.checked) {
                $(this).closest('.frm_radio').addClass('is-checked');
            } else {
                $(this).closest('.frm_radio').removeClass('is-checked');
            }
        });
    });


    // Quand un switch est coché, ajoute une classe sur le container parent
    $('.bloc-form .frm_switch_block input[type="checkbox"]').on('change', function () {
        if($(this).is(':checked')) {
            $(this).closest('.frm_switch_block').addClass('is-checked');
        } else {
            $(this).closest('.frm_switch_block').removeClass('is-checked');
        }
    })


    // Gestion des sliders / range

    const ranges = document.querySelectorAll('.frm_range_container');
    ranges.forEach( el => initRanges(el) );
    function initRanges(el) {
        // update track background gradient, before and after the thumb
        const range = el.querySelector('input');
        range.addEventListener('change', () => updateRangeEl(range) );

        updateRangeEl(range);      // update with default value
    }

    // Actualise le gradient sur le track à chaque changement
    function updateRangeEl(range) {
        const ratio = valueTotalRatio(range.value, range.min, range.max);
        range.style.backgroundImage = getLinearGradientCSS(ratio, '#F9C075', '#122942');
    }

    // Gradient pour gérer une couleur différente de track avant et aprés le thumb
    function getLinearGradientCSS(ratio, leftColor, rightColor) {
        return [
            '-webkit-gradient(',
            'linear, ',
            'left top, ',
            'right top, ',
            'color-stop(' + ratio + ', ' + leftColor + '), ',
            'color-stop(' + ratio + ', ' + rightColor + ')',
            ')'
        ].join('');
    }

    // Convertit la valeur de l'input en pourcentage (entre 0 et 1)
    function valueTotalRatio(value, min, max) {
        return ((value - min) / (max - min)).toFixed(2);
    }

})();
